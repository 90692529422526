<template>
<div class="pt-4 login-tabs-container">
    <div class="mb-4">
      <h1 class="mb-2">Utupay Merchant</h1>
      <h3 class="text-muted">Create your account</h3>
    </div>
    <div class="pt-2">
      <vs-input
        name="email"
        icon-no-border
        icon="email"
        label-placeholder="Email"
        v-model="email"
        size="large"
        class="w-100 mt-4"
      />
      <!-- <span class="text-danger text-sm">{{ errors.first('email') }}</span> -->

      <p class="text-center mt-4 mb-4 text-danger" v-if="status === 'error'">
        {{ message }}
      </p>
      <p class="text-center mt-4 mb-4 text-success" v-if="status === 'success'">
        {{ message }}
      </p>
      <vs-button
        class="w-100 mt-4"
        size="large"
        :disabled="!validateForm"
        @click="confirm"
        >Confirm email</vs-button
      >
      <span>Already have an account? </span>
      <vs-button class="mt-4 font-weight-bold" type="flat" @click="routeToLogin"
        >Login now</vs-button
      >
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
    name: 'ConfirmEmail',
    data () {
        return {
            email: '',
        }
    },
    computed: {
        validateForm () {
            return this.email !== '';
        },
        ...mapState('auth', ['status', 'message'])
    },
    methods: {
        confirm () {
            // Loading
            this.$vs.loading()

            const payload = {
                userDetails: {
                    user_email: this.email.trim(),
                },
                notify: this.$vs.notify,
                closeAnimation: this.$vs.loading.close,
            }
            this.$store.dispatch('auth/confirmEmail', payload)
        },
        ...mapMutations('auth', ['reset']),
        routeToLogin () {
            this.reset();
            this.$router.push('/login').catch(() => {})
        }
    }
}
</script>

<style lang="scss">
    .login-tabs-container {
        min-height: 505px;

        .con-tab {
            padding-bottom: 14px;
        }

        .con-slot-tabs {
            margin-top: 1rem;
        }

        span {
            line-height: 1.6;
            a {
            color: #f26d5b;
            }
        }
    }
</style>